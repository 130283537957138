<template>
  <div>
    <air-forgot-password />
  </div>
</template>
<script>
import AirForgotPassword from '@/components/system/Auth/ForgotPassword'
export default {
  components: {
    AirForgotPassword,
  },
}
</script>
