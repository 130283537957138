<template>
	<div :class="$style.auth">
		<div class="pt-5 pb-5 dF jC align-items-end mt-auto">
			<img src="@/assets/logo.svg" alt="Bildhive Logo" style="max-height:40px;" />
		</div>
		<div class="pl-5 pr-5 pt-5 pb-5 bg-white text-center" :class="$style.container">
			<div class="text-dark mb-4">
				<div class="font-size-30 ">Forgot your password?</div>
				<span class="text-med-gray">Enter your email to reset your password!</span>

			</div>
			<a-form class="mb-4" @submit="forgot">
				<a-form-item>
					<a-input type="email" placeholder="Email Address" v-model="email" size="large"/>
				</a-form-item>
				<button :disabled="loading" :loading="loading" type="submit"
					class="text-center btn btn-primary w-100 font-size-18">Reset my password</button>
			</a-form>
			<div class="text-center font-size-18 pt-2">
				<router-link to="/system/login" class="text-med-gray">
					<a-button type="link" class="font-size-16" icon="arrow-left">
						Back to Log In
					</a-button>
				</router-link>
			</div>
		</div>
		<footer-bh />
	</div>
</template>
<script>
import { parseError, $auth } from 'bh-mod'
import FooterBh from '@/components/common/FooterTerms.vue'
export default {
	components: { FooterBh },
	name: 'AirForgotPassword',
	data: function () {
		return {
			email: '',
			tld: window.location.href.includes('bildhive.com') ? 'com' : 'dev',
			loading: false
		}
	},
	methods: {
		forgot(e) {
			e.preventDefault()
			this.loading = true;
			$auth
				.post(`https://api.bildhive.${this.tld}/auth/forgot-password`, {
					email: this.email,
					url:
						`https://admin.bildhive.${this.tld}/system/reset-password`,
				})
				.then(() => {
					this.$notification["success"]({
						message: 'Reset Password Sent!',
						description: 'A link to reset your password has been emailed to you.',
					});
					this.loading = false;
					this.$router.push('/')
				})
				.catch(err => {
					this.loading = false;
					let error = parseError(err, 'Something went wrong. Please try again.')
					console.error(error)
					this.$message.error(error)
				});
		}
	}
}
</script>
<style lang="scss" module>
@import "@/components/system/Auth/style.module.scss";
</style>
